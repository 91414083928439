import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import CustomPagination from "../../components/customPagination";
import AlertPopup from "../../components/alertPopup";
import AdminFormModal from "../../components/modals/adminFormModal";
import DeleteModal from "../../components/modals/deleteModal";
import LoadingScreen from "../../components/Loading/LoadingScreen";
import { Pagination } from "@mui/material";

const AdminsPage = () => {
  const [allAdminsData, setAllAdminsData] = useState([]);
  const [FilterdAllData, setFilterdAllData] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showEditAdmin, setShowEditAdmin] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [adminID, setAdminID] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [hideTable, setHideTable] = useState(false);
  const [openDeleteAdmin, setOpenDeleteAdmin] = useState(false);
  const [roles, setRoles] = useState([]);

  useMemo(() => {
    if (axios.defaults.permissions) {
      if (axios.defaults.permissions.find((e) => e.name === "create_admin")) {
        setShowAddButton(true);
      }
    }
    const getAdminData = async () => {
      await axios
        .get(`${axios.defaults.baseURL}/administration`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAllData(response.data.data);
            setAllAdminsData(response.data.data);

            setFilterdAllData(response.data.data);

            setLoading(false);
            setAllAdminsData(response.data.data.paginatedData);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getAdminData();
    const getRoles = async () => {
      await axios
        .get(`${axios.defaults.baseURL}/administration/roles`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setRoles(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, page, openPopup, showAddAdmin]);
  const addNewAdmin = () => {
    setShowAddAdmin((current) => !current);
  };
  const closeModal = () => {
    setShowAddAdmin(false);
    setShowEditAdmin(false);
    setAdminID("");
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const handelActiveStatus = (id) => {
    let itemStatus = allAdminsData?.find((e) => e.id === id).status;
    itemStatus === 0 ? (itemStatus = 1) : (itemStatus = 0);
    axios
      .post(
        `${axios.defaults.baseURL}/administration/updateStatus/${id}`,
        { status: itemStatus, _method: "put" },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 202) {
          setUpdateStatus((current) => !current);
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  const handleDeleteButton = (id) => {
    deleteModal();
    setAdminID(id);
  };
  const handleEditButton = (id) => {
    setAdminID(id);
    setShowEditAdmin(true);
  };
  const deleteModal = () => {
    setOpenDeleteAdmin((current) => !current);
  };
  const handlePageChange = (e) => {
    setPage(e);
  };
  const filterAdminsInput = (e) => {
    if (e === "") {
      // setFilterdAllData(allAdminsData);
      setFilterdAllData(allData);
    } else {
      setFilterdAllData(
        allData.filter((f) => {
          return (
            f.name.toLowerCase().includes(e.toLowerCase()) ||
            f.name.toLowerCase().includes(e.toLowerCase())
          );
        })
      );
    }
  };
  const [adminData, setadminData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostsIndex = currentPage * postsPerPage; // 1 * 20 = 20
  const firstPostIndex = lastPostsIndex - postsPerPage; // 20 - 20 = 0
  const currentPosts = FilterdAllData.slice(firstPostIndex, lastPostsIndex);
  const handelPages = (e, p) => {
    setCurrentPage(p);
  };
  return (
    <div>
      <div>
        <div style={{ display: "flex" }}>
          <SideBar />
          <div className="mainContainer">
            <Header />
            <TitleHeader
              title="Manage Admins"
              subTitle={`Admins (${allData?.length})`}
              mainButton="Add New"
              mainButtonFunction={addNewAdmin}
              showAddButton={showAddButton}
              secondaryButton="Upload"
              hideSecondaryButton={true}
              counterNumber={allData.total}
            />
            {!hideTable ? (
              <>
                <div className="filterHeader">
                  <div style={{ padding: "0 30px", display: "flex" }}>
                    <div style={{ position: "relative" }}>
                      <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                      <input
                        type="text"
                        className="searchInput"
                        value={searchInput}
                        onChange={(e) => {
                          filterAdminsInput(e.target.value);
                          setSearchInput(e.target.value);
                        }}
                        placeholder="Search Admins"
                      />
                    </div>
                  </div>
                </div>
                <div className="mainTable">
                  {loading === true ? (
                    <LoadingScreen />
                  ) : (
                    <>
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr className="tableHeaderContainer">
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                          {currentPosts?.map((e) => {
                            return (
                              <tr key={e.id}>
                                <td>{e.name}</td>
                                <td>{e.email}</td>
                                <td>{e.role}</td>
                                <td>
                                  <button
                                    style={{ margin: "auto" }}
                                    onClick={() => {
                                      handelActiveStatus(e.id);
                                    }}
                                    className={
                                      e.status === 1 ? "active" : "blocked"
                                    }
                                  >
                                    {e.status === 1 ? "Active" : "Blocked"}
                                  </button>
                                </td>
                                <td>
                                  <div className="actionWrapper">
                                    <FontAwesomeIcon
                                      icon={faEllipsisVertical}
                                    />
                                    <div className="actionButtonsContainer">
                                      {axios.defaults.permissions.find(
                                        (e) => e.name === "edit_course"
                                      ) ? (
                                        <button
                                          onClick={() => {
                                            handleEditButton(e.id);
                                            setadminData(e);
                                          }}
                                          className="actionButton"
                                        >
                                          edit
                                        </button>
                                      ) : null}
                                      {axios.defaults.permissions.find(
                                        (e) => e.name === "delete_course"
                                      ) ? (
                                        <button
                                          onClick={() =>
                                            handleDeleteButton(e.id)
                                          }
                                          className="actionButton"
                                          style={{ color: "var(--Warning)" }}
                                        >
                                          delete
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div
                        style={{
                          margin: "10px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(allData.length / 10)}
                          color="primary"
                          size={"small"}
                          onChange={handelPages}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {openPopup ? (
        <AlertPopup title={status} subTitle={message} openPopup={handlePopup} />
      ) : null}
      {showAddAdmin ? (
        <AdminFormModal
          title="Add New Admin"
          handleCloseButton={closeModal}
          allData={allAdminsData}
          roles={roles}
          showAlertPopup={showAlertPopup}
          id={adminID}
        />
      ) : showEditAdmin ? (
        <AdminFormModal
          title="Edit Admin"
          allData={allAdminsData}
          roles={roles}
          handleCloseButton={closeModal}
          showAlertPopup={showAlertPopup}
          id={adminID}
          adminData={adminData}
        />
      ) : null}
      {openDeleteAdmin ? (
        <DeleteModal
          title="Admin"
          closeDeleteModal={deleteModal}
          id={adminID}
          showAlertPopup={showAlertPopup}
        />
      ) : null}
    </div>
  );
};

export default AdminsPage;
