import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSearch,
  faEllipsisVertical,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import CustomPagination from "../../components/customPagination";
import AlertPopup from "../../components/alertPopup";
import DeleteModal from "../../components/modals/deleteModal";
import ManagersFormModal from "../../components/modals/managersFormModal";
import UploadManagerPage from "./uploadManagerPage";
import LoadingScreen from "../../components/Loading/LoadingScreen";

const ManagersPage = () => {
  const [allAdminsData, setAllAdminsData] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showEditAdmin, setShowEditAdmin] = useState(false);
  const [adminID, setAdminID] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [organization, setAllOrganization] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [hideTable, setHideTable] = useState(false);
  const [openDeleteAdmin, setOpenDeleteAdmin] = useState(false);
  const [uploadManager, setUploadManager] = useState(false);
  useMemo(() => {
    if (axios.defaults.permissions) {
      if (axios.defaults.permissions.find((e) => e.name === "create_manager")) {
        setShowAddButton(true);
      }
    }
    const getManagersData = async () => {
      await axios
        .get(
          `${axios.defaults.baseURL}/managers?filter[organization.name]=&filter[name]=${searchInput}&filter[email]&page=${page}`,
          {
            headers: {
              authorization: axios.defaults.token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setAllData(response.data.data);
            setLoading(false);
            setAllAdminsData(response.data.data.paginatedData);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    const getOrganizationsList = async () => {
      await axios
        .get(`${axios.defaults.baseURL}/organizations/list`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAllOrganization(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getOrganizationsList();
    getManagersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, searchInput, page, openPopup, showAddAdmin]);
  const handleVerifiedStatus = (id) => {
    console.log(id);
    axios
      .get(`${axios.defaults.baseURL}/managers/resendMail/${id}`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  const addNewAdmin = () => {
    setShowAddAdmin((current) => !current);
  };
  const closeModal = () => {
    setShowAddAdmin(false);
    setShowEditAdmin(false);
    setAdminID("");
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const handelActiveStatus = (id) => {
    let itemStatus = allAdminsData?.find((e) => e.id === id).status;
    itemStatus === "blocked" ? (itemStatus = 1) : (itemStatus = 0);
    axios
      .post(
        `${axios.defaults.baseURL}/managers/updateStatus/${id}`,
        { isActive: itemStatus },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 202) {
          setUpdateStatus((current) => !current);
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  const handleDeleteButton = (id) => {
    deleteModal();
    setAdminID(id);
  };
  const handleEditButton = (id) => {
    setAdminID(id);
    setShowEditAdmin(true);
  };
  const deleteModal = () => {
    setOpenDeleteAdmin((current) => !current);
  };
  const handlePageChange = (e) => {
    setPage(e);
  };
  const handleUploadManager = () => {
    setUploadManager((current) => !current);
  };
  return (
    <div>
      <div>
        <div style={{ display: "flex" }}>
          <SideBar />
          <div className="mainContainer">
            <Header />
            {!uploadManager ? (
              <div>
                <TitleHeader
                  title="Manage Managers"
                  subTitle="Managers"
                  mainButton="Add New"
                  mainButtonFunction={addNewAdmin}
                  showAddButton={showAddButton}
                  secondaryButton="Upload"
                  secondaryButtonFunction={handleUploadManager}
                  hideSecondaryButton={false}
                  counterNumber={allData.total}
                />
                {!hideTable ? (
                  <>
                    <div className="filterHeader">
                      <div style={{ padding: "0 30px", display: "flex" }}>
                        <div style={{ position: "relative" }}>
                          <FontAwesomeIcon
                            icon={faSearch}
                            className="searchIcon"
                          />
                          <input
                            type="text"
                            className="searchInput"
                            onChange={(e) => {
                              setSearchInput(e.target.value);
                            }}
                            defaultValue={searchInput}
                            placeholder="Search Managers"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mainTable">
                      {loading === true ? (
                        <LoadingScreen />
                      ) : (
                        <>
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr className="tableHeaderContainer">
                                <th>Name</th>
                                <th>Email</th>
                                <th>Organization</th>
                                {axios.defaults.permissions.find(
                                  (e) => e.name === "block_manager"
                                ) ? (
                                  <th>Status</th>
                                ) : null}

                                <th>Email Status</th>
                                <th>Actions</th>
                              </tr>
                              {allAdminsData.map((e) => {
                                return (
                                  <tr key={e.id}>
                                    <td>{e.name}</td>
                                    <td>{e.email}</td>
                                    <td>{e.organization}</td>
                                    {axios.defaults.permissions.find(
                                      (e) => e.name === "block_manager"
                                    ) ? (
                                      <td>
                                        <button
                                          style={{ margin: "auto" }}
                                          onClick={() => {
                                            handelActiveStatus(e.id);
                                          }}
                                          className={e.status}
                                        >
                                          {e.status === "active"
                                            ? "Active"
                                            : "Blocked"}
                                        </button>
                                      </td>
                                    ) : null}
                                    <td>
                                      {e.verified === "Verified" ? (
                                        <div className="approvedText">
                                          <FontAwesomeIcon icon={faCheck} />
                                          Verified
                                        </div>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            handleVerifiedStatus(e.id);
                                          }}
                                          className="sendEmailButton"
                                        >
                                          Re-send
                                        </button>
                                      )}
                                    </td>
                                    <td>
                                      <div className="actionWrapper">
                                        <FontAwesomeIcon
                                          icon={faEllipsisVertical}
                                        />
                                        <div className="actionButtonsContainer">
                                          {axios.defaults.permissions.find(
                                            (e) => e.name === "edit_manager"
                                          ) ? (
                                            <button
                                              onClick={() =>
                                                handleEditButton(e.id)
                                              }
                                              className="actionButton"
                                            >
                                              edit
                                            </button>
                                          ) : null}
                                          {axios.defaults.permissions.find(
                                            (e) => e.name === "delete_manager"
                                          ) ? (
                                            <button
                                              onClick={() =>
                                                handleDeleteButton(e.id)
                                              }
                                              className="actionButton"
                                              style={{
                                                color: "var(--Warning)",
                                              }}
                                            >
                                              delete
                                            </button>
                                          ) : null}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <CustomPagination
                            pageNum={page}
                            handlePageChange={handlePageChange}
                            lastPage={allData.lastPage}
                          />
                        </>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            ) : (
              <UploadManagerPage
                handleUploadManager={handleUploadManager}
                closeUploadPage={handleUploadManager}
                showPopup={showAlertPopup}
              />
            )}
          </div>
        </div>
      </div>
      {openPopup ? (
        <AlertPopup title={status} subTitle={message} openPopup={handlePopup} />
      ) : null}
      {showAddAdmin ? (
        <ManagersFormModal
          title="Add New Manager"
          handleCloseButton={closeModal}
          allData={allAdminsData}
          organization={organization}
          showAlertPopup={showAlertPopup}
          id={adminID}
        />
      ) : showEditAdmin ? (
        <ManagersFormModal
          title="Edit Manager"
          allData={allAdminsData}
          organization={organization}
          handleCloseButton={closeModal}
          showAlertPopup={showAlertPopup}
          id={adminID}
        />
      ) : null}
      {openDeleteAdmin ? (
        <DeleteModal
          title="Manager"
          closeDeleteModal={deleteModal}
          id={adminID}
          showAlertPopup={showAlertPopup}
        />
      ) : null}
    </div>
  );
};

export default ManagersPage;
