// import React, { useEffect, useState } from "react";
// import Header from "../../components/header";
// import UserTitleButtons from "../../components/UserTitleButtons";
// import axios from "../../axios";
// import styles from "./UserAssessment.module.css";
// import { Radar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   Filler,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Link } from "react-router-dom";
// import UserAssessmentExam from "./UserAssessmentExam";
// import Starting from "./Starting";

// // Register necessary chart components
// ChartJS.register(
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   Filler,
//   Tooltip,
//   Legend
// );

// function StartAssessment() {
//   // const [getResult, setgetResult] = useState();
//   const [loading, setloading] = useState(false);

//   const [getResult, setgetResult] = useState({
//     status: "Success",
//     message: "",
// data: [
//   {
//     factor_en: "Extroversion",
//     factor_ar: "الانبساطية",
//     desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//     score: 20,
//     scale: "low",
//     score_desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     score_desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//   },
//   {
//     factor_en: "Agreeableness",
//     factor_ar: "القبولية",
//     desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//     score: -8,
//     scale: "low",
//     score_desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     score_desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//   },
//   {
//     factor_en: "Conscientiousness",
//     factor_ar: "اليقظة",
//     desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//     score: 25,
//     scale: "moderate",
//     score_desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     score_desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//   },
//   {
//     factor_en: "Neuroticism",
//     factor_ar: "العُصابية",
//     desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//     score: 19,
//     scale: "low",
//     score_desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     score_desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//   },
//   {
//     factor_en: "Openness to Experience",
//     factor_ar: "الانفتاح على التجربة",
//     desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//     score: 58,
//     scale: "high",
//     score_desc_en:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     score_desc_ar:
//       "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
//   },
// ],
//   });

//   // console.log(getResult);

//   const [assessmentCheck, setassessmentCheck] = useState();
//   const [loading2, setloading2] = useState(false);

//   useEffect(() => {
//     axios
//       .get(`https://ed.edmart.co.uk/api/takeAssessment`, {
//         headers: {
//           authorization: axios.defaults.token,
//         },
//       })
//       .then((res) => res.data)
//       .then((data) => {
//         setassessmentCheck(data);
//         console.log(data);
//         setloading2(true);
//       });
//   }, []);

//   useEffect(() => {
//     axios
//       .get(`https://ed.edmart.co.uk/api/result`, {
//         headers: {
//           authorization: axios.defaults.token,
//         },
//       })
//       .then((res) => res.data)
//       .then((data) => {
//         setgetResult(data);
//         setloading(true);
//       });
//   }, []);

//   // Sample data for the 5 values
//   const data = {
//     labels: [
//       getResult.data[0].factor_en,
//       getResult.data[1].factor_en,
//       getResult.data[2].factor_en,
//       getResult.data[4].factor_en,
//       getResult.data[3].factor_en,
//     ],
//     datasets: [
//       {
//         label: "Personality Traits",
//         data: [
//           getResult.data[0].score,
//           getResult.data[1].score,
//           getResult.data[2].score,
//           getResult.data[4].score,
//           getResult.data[3].score,
//         ], // 5 values between 0 and 50
//         backgroundColor: "rgba(34, 202, 236, 0.2)", // Light blue fill color
//         borderColor: "rgba(34, 202, 236, 1)", // Border color
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Options for configuring the radar chart
//   const options = {
//     scales: {
//       r: {
//         beginAtZero: true, // Start the chart at 0
//         min: 0, // Minimum value on the chart
//         max: 50, // Maximum value on the chart
//         ticks: {
//           stepSize: 10, // The step size for each circle
//           font: {
//             size: 16, // Increase tick font size
//           },
//         },
//         pointLabels: {
//           font: {
//             size: 12, // Increase label font size
//             weight: 500,
//           },
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         labels: {
//           font: {
//             size: 16, // Increase legend font size
//           },
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <Header showLogo={true} />
//       <UserTitleButtons />
//       {!assessmentCheck?.data?.takeAssessment ? (
//         <Starting />
//       ) : (
//         <>
//           <div className={styles.mainTitle}>
//             <h1 className={styles.mainTitlex}>Congratulations!</h1>
//             <h5>
//               You’ve completed your assessment! Below are your scores and
//               insights to help{" "}
//             </h5>
//             <h5>
//               you understand how your traits influence your behavior and
//               decisions.
//             </h5>
//           </div>
//           <div className={styles.graphParent}>
//             <Radar data={data} options={options} />
//           </div>
//           <h1 className={styles.mainTitlex}>
//             Here’s what your big 5 results reveal about you.
//           </h1>
//           <div className={styles.graphParentBoxs}>
//             {getResult?.data?.map((e, i) => {
//               return (
//                 <div className={styles.graphParentBox} key={i}>
//                   <div className={styles.graphParentBoxTitle}>
//                     <h3>{e.factor_en}</h3>
//                     <span>({e.scale})</span>
//                   </div>
//                   <p className={styles.graphParentBoxTitleDesc}>{e.desc_en}</p>
//                   <div className={styles.graphParentBoxDescreption}>
//                     <strong>
//                       {e.factor_en} is {e.scale}
//                     </strong>
//                     , {e.score_desc_en}
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//           <div className={styles.buuut}>
//             <Link to={"/courses"}>Go to my courses</Link>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default StartAssessment;

import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import UserTitleButtons from "../../components/UserTitleButtons";
import axios from "../../axios";
import styles from "./UserAssessment.module.css";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Link } from "react-router-dom";
// import UserAssessmentExam from "./UserAssessmentExam";
import Starting from "./Starting";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Register necessary chart components
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

function StartAssessment() {
  const [loading, setloading] = useState(false);
  const [celebrate, setCelebrate] = useState(false); // State to control celebration effect

  const [getResult, setgetResult] = useState({
    status: "Success",
    message: "",
    data: [
      {
        factor_en: "Extroversion",
        factor_ar: "الانبساطية",
        desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
        score: 20,
        scale: "low",
        score_desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        score_desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
      },
      {
        factor_en: "Agreeableness",
        factor_ar: "القبولية",
        desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
        score: -8,
        scale: "low",
        score_desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        score_desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
      },
      {
        factor_en: "Conscientiousness",
        factor_ar: "اليقظة",
        desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
        score: 25,
        scale: "moderate",
        score_desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        score_desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
      },
      {
        factor_en: "Neuroticism",
        factor_ar: "العُصابية",
        desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
        score: 19,
        scale: "low",
        score_desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        score_desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
      },
      {
        factor_en: "Openness to Experience",
        factor_ar: "الانفتاح على التجربة",
        desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
        score: 49,
        scale: "high",
        score_desc_en:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        score_desc_ar:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
      },
    ],
  });

  useEffect(() => {
    axios
      .get(`https://ed.edmart.co.uk/api/result`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setgetResult(data);
        console.log(getResult);
        setloading(true);
        setCelebrate(true); // Start the celebration
        setTimeout(() => setCelebrate(false), 5000); // Stop after 3 seconds
      });
  }, []);
  const { t } = useTranslation("global");
  // Sample data for the radar chart
  const data = {
    labels: [
      i18next.language === "ar"
        ? getResult.data[0].factor_ar
        : getResult.data[0].factor_en,
      i18next.language === "ar"
        ? getResult.data[1].factor_ar
        : getResult.data[1].factor_en,
      i18next.language === "ar"
        ? getResult.data[2].factor_ar
        : getResult.data[2].factor_en,
      i18next.language === "ar"
        ? getResult.data[4].factor_ar
        : getResult.data[4].factor_en,
      i18next.language === "ar"
        ? getResult.data[3].factor_ar
        : getResult.data[3].factor_en,
    ],
    datasets: [
      {
        label: t("PersonalityTraits"),
        data: [
          getResult.data[0].score,
          getResult.data[1].score,
          getResult.data[2].score,
          getResult.data[4].score,
          getResult.data[3].score,
        ],
        backgroundColor: "rgba(34, 202, 236, 0.2)",
        borderColor: "rgba(34, 202, 236, 1)",
        borderWidth: 1,
      },
    ],
  };
  const [chartOptions, setChartOptions] = useState(getResponsiveOptions());

  useEffect(() => {
    const handleResize = () => setChartOptions(getResponsiveOptions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getResponsiveOptions() {
    const screenWidth = window.innerWidth;

    let fontSize, pointLabelSize;
    if (screenWidth < 600) {
      fontSize = 10;
      pointLabelSize = 8;
    } else if (screenWidth < 992) {
      fontSize = 12;
      pointLabelSize = 10;
    } else {
      fontSize = 16;
      pointLabelSize = 12;
    }

    return {
      scales: {
        r: {
          beginAtZero: true,
          min: 0,
          max: 50,
          ticks: {
            stepSize: 10,
            font: {
              size: fontSize,
            },
          },
          pointLabels: {
            font: {
              size: pointLabelSize,
              weight: 500,
            },
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            font: {
              size: fontSize,
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };
  }
  // // Chart options
  // const options = {
  //   scales: {
  //     r: {
  //       beginAtZero: true,
  //       min: 0,
  //       max: 50,
  //       ticks: {
  //         stepSize: 10,
  //         font: {
  //           size: 16,
  //         },
  //       },
  //       pointLabels: {
  //         font: {
  //           size: 12,
  //           weight: 500,
  //         },
  //       },
  //     },
  //   },
  //   plugins: {
  //     legend: {
  //       labels: {
  //         font: {
  //           size: 16,
  //         },
  //       },
  //     },
  //   },
  // };

  return (
    <div>
      {celebrate && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 1000,
          }}
        >
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}{" "}
      {/* Display confetti if celebrate is true */}
      <div className={styles.mainTitle}>
        <h1 className={styles.mainTitlex}>{t("congrat")}!</h1>
        <h5>{t("congrattitle1")}</h5>
        <h5>{t("congrattitle2")}</h5>
      </div>
      <div className={styles.graphParent}>
        <Radar data={data} options={chartOptions} />
      </div>
      <h1 className={styles.mainTitlex}>{t("big5title")}</h1>
      <div className={styles.graphParentBoxs}>
        {getResult?.data?.map((e, i) => (
          <div className={styles.graphParentBox} key={i}>
            <div className={styles.graphParentBoxTitle}>
              <h3>{i18next.language === "ar" ? e.factor_ar : e.factor_en}</h3>
              <span>({t(`${e.scale}`)})</span>
            </div>
            <p className={styles.graphParentBoxTitleDesc}>
              {i18next.language === "ar" ? e.desc_ar : e.desc_en}
            </p>
            <div className={styles.graphParentBoxDescreption}>
              <strong>
                {i18next.language === "ar" ? e.factor_ar : e.factor_en}{" "}
                {t("is")} {t(`${e.scale}`)}
              </strong>
              , {i18next.language === "ar" ? e.score_desc_ar : e.score_desc_en}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.buuut}>
        <Link to={"/user-courses"}>{t("coursesbtn")}</Link>
      </div>
    </div>
  );
}

export default StartAssessment;
