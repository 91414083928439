// import style from "./popupstyle.module.css";
// import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   FormHelperText,
// } from "@mui/material";
// function EditEquationContent({ theData, onCancel }) {
//   const [formData, setFormData] = useState({
//     name_ar: theData.name_ar,
//     name_en: theData.name_en,
//     desc_ar: theData.desc_ar,
//     desc_en: theData.desc_en,
//     high_desc_ar: theData.high_desc_ar,
//     high_desc_en: theData.high_desc_en,
//     moderate_desc_ar: theData.moderate_desc_ar,
//     moderate_desc_en: theData.moderate_desc_en,
//     low_desc_ar: theData.low_desc_ar,
//     low_desc_en: theData.low_desc_en,
//   });

//   const [errors, setErrors] = useState({});

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   // Validation: Check for empty fields
//   const validateFields = () => {
//     let fieldErrors = {};
//     Object.keys(formData).forEach((key) => {
//       if (!formData[key].trim()) {
//         fieldErrors[key] = "This field is required";
//       }
//     });
//     setErrors(fieldErrors);
//     return Object.keys(fieldErrors).length === 0; // Return true if no errors
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validateFields()) {
//       console.log(formData); // Pass the updated data to the parent or an API
//     }
//   };
//   return (
//     <div className={style.parentpopup}>
//       <div className={style.popupdiv}>
//         <h2 className={style.title}>Edit Equation : {theData.name_en}</h2>
//         <form
//           onSubmit={handleSubmit}
//           style={{ maxWidth: 600, margin: "0 auto" }}
//         >
//           <h3>Edit Data</h3>

//           {/* Arabic Name */}
//           <FormControl
//             fullWidth
//             margin="normal"
//             error={Boolean(errors.name_ar)}
//           >
//             <TextField
//               label="Name (AR)"
//               name="name_ar"
//               value={formData.name_ar}
//               onChange={handleInputChange}
//               helperText={errors.name_ar}
//               error={Boolean(errors.name_ar)}
//             />
//           </FormControl>

//           {/* English Name */}
//           <FormControl
//             fullWidth
//             margin="normal"
//             error={Boolean(errors.name_en)}
//           >
//             <TextField
//               label="Name (EN)"
//               name="name_en"
//               value={formData.name_en}
//               onChange={handleInputChange}
//               helperText={errors.name_en}
//               error={Boolean(errors.name_en)}
//             />
//           </FormControl>

//           {/* Arabic Description */}
//           <FormControl
//             fullWidth
//             margin="normal"
//             error={Boolean(errors.desc_ar)}
//           >
//             <TextField
//               label="Description (AR)"
//               name="desc_ar"
//               multiline
//               // rows={3}
//               value={formData.desc_ar}
//               onChange={handleInputChange}
//               helperText={errors.desc_ar}
//               error={Boolean(errors.desc_ar)}
//             />
//           </FormControl>

//           {/* English Description */}
//           <FormControl
//             fullWidth
//             margin="normal"
//             error={Boolean(errors.desc_en)}
//           >
//             <TextField
//               label="Description (EN)"
//               name="desc_en"
//               multiline
//               // rows={5}
//               value={formData.desc_en}
//               onChange={handleInputChange}
//               helperText={errors.desc_en}
//               error={Boolean(errors.desc_en)}
//             />
//           </FormControl>

//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             style={{ marginTop: 20 }}
//           >
//             Save
//           </Button>
//         </form>
//         <div
//           className={style.buttons}
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginTop: "10px",
//           }}
//         >
//           <Button
//             onClick={onCancel}
//             className={style.buttonsCancel}
//             style={{ width: "49%", border: "1px solid #1976d2" }}
//           >
//             Cancel
//           </Button>
//           <Button
//             variant="contained"
//             color="primary"
//             type="submit"
//             style={{ width: "49%" }}
//           >
//             Update
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default EditEquationContent;

import React, { useState } from "react";
import style from "./popupstyle.module.css"; // Fixed the import for CSS module
import { TextField, Button, FormControl } from "@mui/material";
import axios from "../../axios";
import Swal from "sweetalert2";

function EditEquationContent({ theData, onCancel }) {
  const [formData, setFormData] = useState({
    _method: "PUT",
    name_ar: theData.name_ar,
    name_en: theData.name_en,
    desc_ar: theData.desc_ar,
    desc_en: theData.desc_en,
    high_desc_ar: theData.high_desc_ar,
    high_desc_en: theData.high_desc_en,
    moderate_desc_ar: theData.moderate_desc_ar,
    moderate_desc_en: theData.moderate_desc_en,
    low_desc_ar: theData.low_desc_ar,
    low_desc_en: theData.low_desc_en,
  });

  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validation: Check for empty fields
  const validateFields = () => {
    let fieldErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key].trim()) {
        fieldErrors[key] = "This field is required";
      }
    });
    setErrors(fieldErrors);
    return Object.keys(fieldErrors).length === 0; // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      console.log(formData); // Pass the updated data to the parent or an API
      let url = `${axios.defaults.baseURL}/administration/factors/${theData.id}`;
      console.log(formData);
      // Submit the updated formData
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to edit this Equation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Edit it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(url, formData, {
              headers: {
                authorization: axios.defaults.token,
              },
            })
            .then((res) => {
              console.log(res);
              Swal.fire({
                title: "Editing!",
                text: "Your Equation has been Edited.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong! or you don't have a permission",
              });
            });
        }
      });
    }
  };

  return (
    <div className={style.parentpopup}>
      <div
        className={style.popupdiv}
        style={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <h2 className={style.title}>Edit: {theData.name_en}</h2>
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: 600, margin: "0 auto" }}
        >
          <h3>Edit Data</h3>

          {/* Arabic Name */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.name_ar)}
          >
            <TextField
              label="Name (AR)"
              name="name_ar"
              value={formData.name_ar}
              onChange={handleInputChange}
              helperText={errors.name_ar}
              error={Boolean(errors.name_ar)}
            />
          </FormControl>

          {/* English Name */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.name_en)}
          >
            <TextField
              label="Name (EN)"
              name="name_en"
              value={formData.name_en}
              onChange={handleInputChange}
              helperText={errors.name_en}
              error={Boolean(errors.name_en)}
            />
          </FormControl>

          {/* Arabic Description */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.desc_ar)}
          >
            <TextField
              label="Description (AR)"
              name="desc_ar"
              multiline
              value={formData.desc_ar}
              onChange={handleInputChange}
              helperText={errors.desc_ar}
              error={Boolean(errors.desc_ar)}
            />
          </FormControl>

          {/* English Description */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.desc_en)}
          >
            <TextField
              label="Description (EN)"
              name="desc_en"
              multiline
              value={formData.desc_en}
              onChange={handleInputChange}
              helperText={errors.desc_en}
              error={Boolean(errors.desc_en)}
            />
          </FormControl>

          {/* Arabic High Description */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.high_desc_ar)}
          >
            <TextField
              label="High Description (AR)"
              name="high_desc_ar"
              multiline
              value={formData.high_desc_ar}
              onChange={handleInputChange}
              helperText={errors.high_desc_ar}
              error={Boolean(errors.high_desc_ar)}
            />
          </FormControl>

          {/* English High Description */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.high_desc_en)}
          >
            <TextField
              label="High Description (EN)"
              name="high_desc_en"
              multiline
              value={formData.high_desc_en}
              onChange={handleInputChange}
              helperText={errors.high_desc_en}
              error={Boolean(errors.high_desc_en)}
            />
          </FormControl>

          {/* Arabic Moderate Description */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.moderate_desc_ar)}
          >
            <TextField
              label="Moderate Description (AR)"
              name="moderate_desc_ar"
              multiline
              value={formData.moderate_desc_ar}
              onChange={handleInputChange}
              helperText={errors.moderate_desc_ar}
              error={Boolean(errors.moderate_desc_ar)}
            />
          </FormControl>

          {/* English Moderate Description */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.moderate_desc_en)}
          >
            <TextField
              label="Moderate Description (EN)"
              name="moderate_desc_en"
              multiline
              value={formData.moderate_desc_en}
              onChange={handleInputChange}
              helperText={errors.moderate_desc_en}
              error={Boolean(errors.moderate_desc_en)}
            />
          </FormControl>

          {/* Arabic Low Description */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.low_desc_ar)}
          >
            <TextField
              label="Low Description (AR)"
              name="low_desc_ar"
              multiline
              value={formData.low_desc_ar}
              onChange={handleInputChange}
              helperText={errors.low_desc_ar}
              error={Boolean(errors.low_desc_ar)}
            />
          </FormControl>

          {/* English Low Description */}
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.low_desc_en)}
          >
            <TextField
              label="Low Description (EN)"
              name="low_desc_en"
              multiline
              value={formData.low_desc_en}
              onChange={handleInputChange}
              helperText={errors.low_desc_en}
              error={Boolean(errors.low_desc_en)}
            />
          </FormControl>

          <div
            className={style.buttons}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={onCancel}
              className={style.buttonsCancel}
              style={{ width: "49%", border: "1px solid #1976d2" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: "49%" }}
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditEquationContent;
