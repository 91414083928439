import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPagination from "../../components/customPagination";
import { faFilter, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import FilterSelectInput from "../../components/filterSelectInput";
import AlertPopup from "../../components/alertPopup";
import FilterTextInput from "../../components/filterTextInput";
import LoadingScreen from "../../components/Loading/LoadingScreen";
const CoursesPage = () => {
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hideTable, setHideTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [level, setLevel] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [visibility, setVisibility] = useState("");
  const [provider, setProvider] = useState("");

  const visibilityList = [
    { id: 0, name: "hidden" },
    { id: 1, name: "visible" },
  ];
  useMemo(() => {
    const getCourses = async () => {
      let url = `${axios.defaults.baseURL}/organization/courses?visibility=${visibility}&filter[title]=${searchInput}&filter[price]=${price}&filter[duration]=${duration}&filter[provider_id]=${provider}&filter[level_id]=${level}&page=${page}`;
      await axios
        .get(url, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setAllData(response.data.data);
            setAllCoursesData(response.data.data.paginatedData);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    const getLists = async () => {
      await axios
        .get(`${axios.defaults.baseURL}/courses/getLists`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLists(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getLists();
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchInput,
    page,
    openPopup,
    visibility,
    provider,
    level,
    duration,
    price,
  ]);
  const handlePageChange = (e) => {
    setPage(e);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handleVisibleStatus = (id) => {
    axios
      .get(
        `${axios.defaults.baseURL}/organization/courses/updateVisibility/${id}`,
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 202) {
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  const handleFilterButton = () => {
    setShowFilter((current) => !current);
    if (showFilter === true) {
      setLevel("");
      setVisibility("");
      setProvider("");
      setPrice("");
      setDuration("");
    }
  };
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      <div className="mainContainer">
        <Header />
        <div>
          <TitleHeader
            title="Manage Courses"
            subTitle="Courses"
            mainButton="Add New"
            showAddButton={false}
            counterNumber={allData.total}
          />
          {!hideTable ? (
            <>
              <div className="filterHeader">
                <div style={{ padding: "0 30px", display: "flex" }}>
                  <div style={{ position: "relative", marginRight: "16px" }}>
                    <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                    <input
                      type="text"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      className="searchInput"
                      placeholder="Search Courses"
                    />
                  </div>

                  {!showFilter ? (
                    <button
                      className="filterButton"
                      onClick={handleFilterButton}
                    >
                      <FontAwesomeIcon
                        icon={faFilter}
                        style={{ marginRight: "10px" }}
                      />
                      Filter
                    </button>
                  ) : (
                    <button
                      className="filterButton"
                      onClick={handleFilterButton}
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{ marginRight: "10px" }}
                      />
                      Reset
                    </button>
                  )}
                </div>
              </div>
              <div className="mainTable">
                {showFilter ? (
                  <div className="filterInputsContainer">
                    <FilterSelectInput
                      label="Provider"
                      value={provider}
                      options={lists.providers}
                      onChange={(e) => {
                        setProvider(e.target.value);
                      }}
                    />
                    <FilterTextInput
                      label="Duration"
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                    />
                    <FilterTextInput
                      label="Price"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                    <FilterSelectInput
                      label="Level"
                      value={level}
                      options={lists.levels}
                      onChange={(e) => setLevel(e.target.value)}
                    />
                    <FilterSelectInput
                      label="Visibility"
                      value={visibility}
                      options={visibilityList}
                      onChange={(e) => {
                        setVisibility(e.target.value);
                      }}
                    />
                  </div>
                ) : null}
                {loading === true ? (
                  <LoadingScreen />
                ) : (
                  <>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr className="tableHeaderContainer">
                          <th>Course Name</th>
                          <th>Provider</th>
                          <th>duration</th>
                          <th>Price</th>
                          <th>Level</th>
                          <th className="visibleTitle">
                            Visible
                            <p className="infoSpan">
                              i
                              <span className="infoText">
                                <strong>Switch on the toggle</strong>, if you
                                want a certain course to be visible to the user.
                              </span>
                            </p>
                          </th>
                        </tr>
                        {allCoursesData?.map((e) => {
                          return (
                            <tr key={e.id}>
                              <td>{e.title}</td>
                              <td>{e.provider}</td>
                              <td>{e.duration} Hours</td>
                              <td>{e.price} EGP</td>
                              <td>{e.level}</td>
                              <td>
                                <div className="actionWrapper">
                                  <label className="switch">
                                    <input
                                      className={
                                        e.visible === true
                                          ? "toggleChecked"
                                          : "toggleUnChecked"
                                      }
                                      type="checkbox"
                                      onChange={() => {
                                        handleVisibleStatus(e.id);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <CustomPagination
                      pageNum={page}
                      handlePageChange={handlePageChange}
                      lastPage={allData.lastPage}
                    />
                  </>
                )}
              </div>
            </>
          ) : null}
          {openPopup ? (
            <AlertPopup
              title={status}
              subTitle={message}
              openPopup={handlePopup}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CoursesPage;
