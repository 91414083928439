import React from "react";
import style from "./UserLanding.module.css";
import logo from "../../assets/images/loogo.svg";
import imagee from "../../assets/images/landingimage.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../../components/LanguageSwitch";

function UserLanding() {
  const { t } = useTranslation("global");

  return (
    <div className={style.userLandingPage}>
      <div className={style.header}>
        <div>
          <img src={logo} alt="" />
        </div>
        <LanguageSwitch />
        <Link className={style.buutt} to={"/login"}>
          {t("homepagebigfive.login")} / {t("homepagebigfive.signup")}
        </Link>
      </div>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.contentImage}>
            <img src={imagee} alt="" />
          </div>
          <div className={style.contentbigTitle}>
            <h1>
              {t("homepagebigfive.title1")}{" "}
              <span>{t("homepagebigfive.title1span")}</span>
            </h1>
            <h1>{t("homepagebigfive.title2")}</h1>
          </div>
          <div className={style.contentbigpragraph}>
            {t("homepagebigfive.desc")}
          </div>
          <Link className={style.contentbigstart} to={"/assessment"}>
            {t("homepagebigfive.button")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UserLanding;
