// import React, { useState } from "react";
// import "./EquationForm.css"; // Importing the CSS file

// const EquationForm = ({ onValueChange }) => {
//   // State for the input number (20)
//   const [inputValue, setInputValue] = useState("20");

//   // State for the dropdown values and operators
//   const [dropdownValues, setDropdownValues] = useState(Array(10).fill(1));
//   const [operators, setOperators] = useState(Array(10).fill("+")); // 9 operators for 10 numbers

//   // Handle input change for the initial value
//   const handleInputChange = (e) => {
//     setInputValue(e.target.value);
//   };

//   // Handle dropdown value change
//   const handleDropdownChange = (index, value) => {
//     const updatedValues = [...dropdownValues];
//     updatedValues[index] = value;
//     setDropdownValues(updatedValues);
//   };

//   // Handle operator change between "+" and "-"
//   const handleOperatorChange = (index) => {
//     const updatedOperators = [...operators];
//     updatedOperators[index] = updatedOperators[index] === "+" ? "-" : "+";
//     setOperators(updatedOperators);
//   };

//   // Generate the final equation string
//   const generateEquationString = () => {
//     let equation = inputValue;
//     dropdownValues.forEach((value, index) => {
//       if (index < operators.length) {
//         equation += operators[index] + value; // Add operator and value
//       } else {
//         equation += value; // For the last number, no operator
//       }
//     });
//     onValueChange(equation);
//     return equation;
//   };

//   return (
//     <div className="equation-form-container">
//       <h3>Equation</h3>
//       <div className="equation-flex-container">
//         {/* Input for the initial value */}
//         <input
//           type="text"
//           value={inputValue}
//           onChange={handleInputChange}
//           className="equation-input"
//         />

//         {/* Dropdowns and operators */}
//         {dropdownValues.map((dropdownValue, index) => (
//           <span key={index} className="parentformlaspan">
//             {/* Operator (only add operator if it's not the last dropdown) */}
//             {index < operators.length && (
//               <button
//                 type="button"
//                 onClick={() => handleOperatorChange(index)}
//                 className="equation-operator"
//               >
//                 {operators[index]}
//               </button>
//             )}

//             {/* Dropdown for numbers 1-50 */}
//             <select
//               value={dropdownValue}
//               onChange={(e) =>
//                 handleDropdownChange(index, parseInt(e.target.value, 10))
//               }
//               className="equation-dropdown"
//             >
//               {[...Array(50)].map((_, i) => (
//                 <option key={i} value={i + 1}>
//                   {i + 1}
//                 </option>
//               ))}
//             </select>
//           </span>
//         ))}
//       </div>

//       {/*<h4>Generated Equation String:</h4>
//       <p className="generated-equation">{generateEquationString()}</p>*/}

//       <p className="generated-equation">
//         Formula result : {generateEquationString()}
//       </p>
//     </div>
//   );
// };

// export default EquationForm;
import React, { useState, useEffect } from "react";
import "./EquationForm.css"; // Importing the CSS file

const EquationForm = ({ defaultEquation, onValueChange }) => {
  // State for the input number (20) and other components
  console.log(defaultEquation);
  const [inputValue, setInputValue] = useState("20");
  const [dropdownValues, setDropdownValues] = useState(Array(10).fill(1));
  const [operators, setOperators] = useState(Array(9).fill("+")); // 9 operators for 10 numbers

  // Parse the default equation string into inputValue, dropdownValues, and operators
  useEffect(() => {
    if (defaultEquation) {
      const parts = defaultEquation.match(/(\d+|[-+])/g); // Split the equation into numbers and operators
      if (parts && parts.length) {
        setInputValue(parts[0]); // The first number is the input value
        const tempOperators = [];
        const tempDropdownValues = [];

        for (let i = 1; i < parts.length; i++) {
          if (parts[i] === "+" || parts[i] === "-") {
            tempOperators.push(parts[i]); // Add the operator
          } else {
            tempDropdownValues.push(Number(parts[i])); // Add the dropdown value (as number)
          }
        }

        setDropdownValues(tempDropdownValues); // Set dropdown values
        setOperators(tempOperators); // Set operators
      }
    }
  }, [defaultEquation]);

  // Handle input change for the initial value
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle dropdown value change
  const handleDropdownChange = (index, value) => {
    const updatedValues = [...dropdownValues];
    updatedValues[index] = value;
    setDropdownValues(updatedValues);
  };

  // Handle operator change between "+" and "-"
  const handleOperatorChange = (index) => {
    const updatedOperators = [...operators];
    updatedOperators[index] = updatedOperators[index] === "+" ? "-" : "+";
    setOperators(updatedOperators);
  };

  // Generate the final equation string
  const generateEquationString = () => {
    let equation = inputValue;
    dropdownValues.forEach((value, index) => {
      if (index < operators.length) {
        equation += operators[index] + value; // Add operator and value
      } else {
        equation += value; // For the last number, no operator
      }
    });
    onValueChange(equation); // Send the equation string back to parent
    return equation;
  };

  return (
    <div className="equation-form-container">
      <h3>Equation</h3>
      <div className="equation-flex-container">
        {/* Input for the initial value */}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          className="equation-input"
        />

        {/* Dropdowns and operators */}
        {dropdownValues.map((dropdownValue, index) => (
          <span key={index} className="parentformlaspan">
            {/* Operator (only add operator if it's not the last dropdown) */}
            {index < operators.length && (
              <button
                type="button"
                onClick={() => handleOperatorChange(index)}
                className="equation-operator"
              >
                {operators[index]}
              </button>
            )}

            {/* Dropdown for numbers 1-50 */}
            <select
              value={dropdownValue}
              onChange={(e) =>
                handleDropdownChange(index, parseInt(e.target.value, 10))
              }
              className="equation-dropdown"
            >
              {[...Array(50)].map((_, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </span>
        ))}
      </div>

      {/*<h4>Generated Equation String:</h4>
      <p className="generated-equation">{generateEquationString()}</p>*/}

      <p className="generated-equation">
        Formula result: {generateEquationString()}
      </p>
    </div>
  );
};

export default EquationForm;
