import React from "react";
import flagAr from "../assets/images/egypt.png";
import flagEn from "../assets/images/united-kingdom.png";
import { useTranslation } from "react-i18next";
function LanguageSwitch() {
  const [i, i18n] = useTranslation("global");

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    console.log(lang);
    document.body.dir = lang === "ar" ? "rtl" : "ltr";
  };

  return (
    <div className="language-switcher">
      {i18n.language === "en" ? (
        <div
          className="lang-user text-decoration-none  d-flex align-items-center gap-2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            handleChangeLanguage("ar");
          }}
        >
          <img
            src={flagAr}
            alt="userImage"
            width={"30px"}
            height={"30px"}
            style={{ cursor: "pointer" }}
          />
          <span
            className="text-lang cursor-pointer"
            style={{
              padding: "0 10px",
              cursor: "pointer",
            }}
          >
            العربية
          </span>
        </div>
      ) : (
        <div
          className="lang-user text-decoration-none  d-flex align-items-center gap-2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            handleChangeLanguage("en");
          }}
        >
          <img
            src={flagEn}
            alt="userImage"
            width={"30px"}
            height={"30px"}
            style={{ cursor: "pointer" }}
          />
          <span
            className="text-lang"
            style={{
              padding: "0 10px",
              cursor: "pointer",
            }}
          >
            En
          </span>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitch;
