import React, { useEffect, useState } from "react";
import LoadingScreen from "../../components/Loading/LoadingScreen";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import rightmark from "../../assets/images/correct.png";
import wrongmark from "../../assets/images/delete.png";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "../../axios";
import styles from "./AllUsers.module.css";
function AllUsers() {
  const [allData, setAllData] = useState([]);
  const [filterdAllData, setFilterdAllData] = useState([]);
  const [userTookAssessment, setuserTookAssessment] = useState(0);
  const [userDontTook, setuserDontTook] = useState(0);
  const [loading, setLoading] = useState(false);
  const [HidenTable, setHidenTable] = useState(false);

  useEffect(() => {
    let url = `${axios.defaults.baseURL}/endUsers`;
    axios
      .get(url, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        setLoading(true);
        setAllData(response.data.data);
        console.log(response.data.data);
        setFilterdAllData(response.data.data);
        setuserTookAssessment(
          response.data.data.filter((e) => {
            return e.took_assessment;
          })
        );
        setuserDontTook(
          response.data.data.filter((e) => {
            return !e.took_assessment;
          })
        );
        // setAllRequestsData(response.data.data.paginatedData);
      })
      .catch((error) => {
        if (
          error.response.data.message === "Invalid ability provided." &&
          error.response.status === 403
        ) {
          setHidenTable(true);
        }
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostsIndex = currentPage * postsPerPage; // 1 * 20 = 20
  const firstPostIndex = lastPostsIndex - postsPerPage; // 20 - 20 = 0
  const currentPosts = filterdAllData.slice(firstPostIndex, lastPostsIndex);
  const handelPages = (e, p) => {
    setCurrentPage(p);
  };

  const filterUsersInput = (e) => {
    if (e === "") {
      setFilterdAllData(allData);
    } else {
      setFilterdAllData(
        allData.filter((f) => {
          return (
            f.name.toLowerCase().includes(e.toLowerCase()) ||
            f.email.toLowerCase().includes(e.toLowerCase())
          );
        })
      );
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      {!HidenTable && (
        <div className="mainContainer">
          <Header />
          <div>
            <div
              style={{
                height: "100px",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 30px",
                alignItems: "center",
              }}
            >
              <div style={{ color: " var(--c600)" }}>
                <h1 style={{ fontSize: "30px" }}>All Users</h1>
                <p style={{ fontWeight: "600" }}>
                  Users Who Have Taken the Assessment (
                  {userTookAssessment.length})
                </p>
                <p style={{ fontWeight: "600" }}>
                  Users Who Haven't Taken the Assessment ({userDontTook.length})
                </p>
              </div>
              <div></div>
            </div>

            <>
              <div className="filterHeader">
                <div style={{ padding: "0 30px", display: "flex" }}>
                  <div style={{ position: "relative" }}>
                    <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                    {
                      <input
                        type="text"
                        // value={searchInput}
                        onChange={(e) => filterUsersInput(e.target.value)}
                        className="searchInput"
                        placeholder="Search Question"
                      />
                    }
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="mainTable" style={{ backgroundColor: "white" }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 650 }}
                      className="tableHeaderContainer"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="centerTableCell">ID</TableCell>
                          <TableCell className="centerTableCell">
                            Name
                          </TableCell>

                          <TableCell className="centerTableCell">
                            Email
                          </TableCell>
                          <TableCell className="centerTableCell">
                            Took Assessment
                          </TableCell>
                          <TableCell className="centerTableCell">
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPosts.map((e, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell className="centerTableCell">
                              {e.id}
                            </TableCell>
                            <TableCell className="centerTableCell">
                              {e.name}
                            </TableCell>
                            <TableCell className="centerTableCell">
                              {e.email}
                            </TableCell>
                            <TableCell className="centerTableCell">
                              {e.took_assessment ? (
                                <img
                                  src={rightmark}
                                  alt="yes"
                                  style={{ width: "30px", height: "30px" }}
                                />
                              ) : (
                                <img
                                  src={wrongmark}
                                  alt="no"
                                  style={{ width: "30px", height: "30px" }}
                                />
                              )}
                            </TableCell>

                            <TableCell className="centerTableCell">
                              <span
                                className={styles.spanx}
                                style={{
                                  backgroundColor:
                                    e.status === "active"
                                      ? "#4caf50"
                                      : "#f44336",
                                }}
                              >
                                {e.status}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <LoadingScreen />
              )}
              <div
                className="d-flex justify-content-end m-2"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "15px",
                }}
              >
                <Pagination
                  count={Math.ceil(allData.length / 10)}
                  color="primary"
                  size={"small"}
                  onChange={handelPages}
                />
              </div>
            </>
          </div>
        </div>
      )}
    </div>
  );
}

export default AllUsers;
