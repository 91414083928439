import { useEffect, useMemo, useState } from "react";
import axios from "../../axios";
import TextInput from "../textInput";
import SelectInput from "../selectInput";

const OrganizationFormModal = ({
  handleCloseButton,
  showAlertPopup,
  title,
  id,
  allData,
}) => {
  const [organizationName, setOrganizationName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [nameValidation, setNameValidation] = useState(false);
  const [addressValidation, setAddressValidation] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [organizationNameID, setOrganizationID] = useState("");

  useMemo(() => {
    setOrganizationID(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = [
    { name: "Blocked", id: 0 },
    { name: "Active", id: 1 },
  ];
  useEffect(() => {
    if (organizationNameID) {
      handleDefaultValue();
    } else {
      setStatus(options[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDefaultValue = () => {
    let allDataArray = allData;
    let itemData = allDataArray?.find(
      (itemID) => itemID.id === organizationNameID
    );
    setOrganizationName(itemData.name);
    setPhone(itemData.phone);
    setAddress(itemData.address);
    itemData.status === "active"
      ? setStatus(options[1].id)
      : setStatus(options[0].id);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    let axiosBody = {};
    organizationNameID
      ? (url = `${axios.defaults.baseURL}/organizations/${organizationNameID}`)
      : (url = `${axios.defaults.baseURL}/organizations`);

    organizationNameID
      ? (axiosBody = {
          name: organizationName,
          phone: phone,
          address: address,
          status: status,
          _method: "put",
        })
      : (axiosBody = {
          name: organizationName,
          phone: phone,
          address: address,
          status: status,
        });
    await axios
      .post(url, axiosBody, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          showAlertPopup(response.data.status, response.data.message);
          handleCloseButton();
          setOrganizationName("");
          setPhone("");
          setAddress("");
          setStatus("");
        } else if (response.status === 202) {
          showAlertPopup(response.data.status, response.data.message);
          handleCloseButton();
          setOrganizationName("");
          setPhone("");
          setAddress("");
          setStatus("");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.data.address);
          setNameValidation(error.response.data.data.name);
          setAddressValidation(error.response.data.data.address);
          setPhoneValidation(error.response.data.data.phone);
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  return (
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="formContainer">
          <p className="welcome-text">{title}</p>
          <form className="form" method="post">
            <TextInput
              label="Organization Name"
              value={organizationName}
              required={true}
              validation={nameValidation}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
            <TextInput
              label="Phone"
              value={phone}
              required={false}
              validation={phoneValidation}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextInput
              label="Address"
              value={address}
              required={false}
              validation={addressValidation}
              onChange={(e) => setAddress(e.target.value)}
            />
            <SelectInput
              label="Status"
              options={options}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            />
            <div
              className="modalFormButtons"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <button className="secondaryButton" onClick={handleCloseButton}>
                Cancel
              </button>
              <button
                className="primaryButton"
                type="submit"
                onClick={handleSubmit}
              >
                {organizationNameID ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrganizationFormModal;
