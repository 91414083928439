import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import AlertPopup from "../../components/alertPopup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddRoleModal from "../../components/modals/addNewRoleModal";
import LoadingScreen from "../../components/Loading/LoadingScreen";
const SystemRolePage = () => {
  const [rolesData, setRolesData] = useState([]);
  const [roleID, setRoleID] = useState(1);
  const [allPermissions, setAllPermissions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [checkPermission, setCheckPermission] = useState();
  const [showAddRole, setShowAddRole] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAddButton, setShowAddButton] = useState(false);
  const [hideTable, setHideTable] = useState(false);
  useMemo(() => {
    if (axios.defaults.permissions) {
      if (axios.defaults.permissions.find((e) => e.name === "create_role")) {
        setShowAddButton(true);
      }
      if (!axios.defaults.permissions.find((e) => e.name === "list_roles")) {
        setHideTable(true);
      }
    }
    const getRoles = async () => {
      console.log("testsetewt");
      await axios
        .get(`${axios.defaults.baseURL}/administration/roles`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            setRolesData(response.data.data);
            handelRolePermissions(response.data.data[0].id);
            setRoleID(response.data.data[0].id);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getRoles();
    // if (openPopup) {
    //   getRoles();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!showAddRole]);

  const handelRolePermissions = (id) => {
    setLoading(true);
    axios
      .get(`${axios.defaults.baseURL}/administration/roles/permissions/${id}`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAllPermissions(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.message === "Invalid ability provided.") {
            setLoading(false);
            setHideTable(true);
          }
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  const addNewRole = () => {
    setShowAddRole((current) => !current);
  };
  const closeModal = () => {
    setShowAddRole((current) => !current);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const handleCheckedPermission = (id) => {
    let allPermissionsArray = allPermissions;
    let itemPermission = allPermissionsArray.find((itemID) => itemID.id === id);
    itemPermission.roleInclude
      ? (itemPermission.roleInclude = false)
      : (itemPermission.roleInclude = true);
    setAllPermissions(allPermissionsArray);
    setCheckPermission((current) => !current);
  };
  const handleSubmitPermissions = () => {
    const newPermissions = allPermissions.filter((item) => {
      return item.roleInclude === true;
    });
    let permissionsNamesArr = [];
    for (let permission in newPermissions) {
      permissionsNamesArr.push(newPermissions[permission].name);
    }
    axios
      .post(
        `${axios.defaults.baseURL}/administration/roles/${roleID}`,
        { _method: "put", permissions: permissionsNamesArr },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 202) {
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  // console.log(allPermissions);
  // console.log("---------------------------------");
  // console.log(rolesData);
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      <div className="mainContainer">
        {showAddRole ? (
          <AddRoleModal
            handleCloseButton={closeModal}
            showAlertPopup={showAlertPopup}
          />
        ) : (
          ""
        )}
        <Header />
        <div>
          <TitleHeader
            title="System Roles"
            subTitle="System Roles"
            mainButton="Add New"
            showAddButton={showAddButton}
            mainButtonFunction={addNewRole}
            hideSecondaryButton={true}
          />
          {!hideTable ? (
            <>
              <div className="filterHeader">
                <div
                  style={{
                    padding: "0 30px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div className="selectInputContainer">
                    <label htmlFor="ChooseRole" style={{ marginRight: "10px" }}>
                      Choose Role:
                    </label>
                    <select
                      className="selectInput"
                      onChange={(e) => {
                        handelRolePermissions(e.target.value);
                        setRoleID(e.target.value);
                      }}
                      name="roles"
                      id="ChooseRole"
                    >
                      {rolesData.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="mainTable" style={{ backgroundColor: "white" }}>
                {!loading ? (
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 650 }}
                      className="tableHeaderContainer"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan="5">PERMISSIONS</TableCell>
                          <TableCell align="center"> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allPermissions?.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell colSpan="5">{row.title}</TableCell>
                            <TableCell align="center">
                              {axios.defaults.permissions.find(
                                (e) => e.name === "edit_role"
                              ) ? (
                                <label className="switch">
                                  <input
                                    className={
                                      row.roleInclude
                                        ? "toggleChecked"
                                        : "toggleUnChecked"
                                    }
                                    type="checkbox"
                                    onChange={() =>
                                      handleCheckedPermission(row.id)
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <LoadingScreen />
                )}
              </div>
              <div className="mainTable submitPermissionButton">
                <button
                  className="primaryButton"
                  onClick={handleSubmitPermissions}
                >
                  save
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {openPopup ? (
        <AlertPopup
          title={status}
          subTitle={message}
          handleCloseButton={closeModal}
          openPopup={handlePopup}
        />
      ) : null}
    </div>
  );
};

export default SystemRolePage;
