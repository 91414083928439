import { useEffect, useMemo, useState } from "react";
import axios from "../../axios";
import Header from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Slider from "@mui/material/Slider";
import CustomPagination from "../../components/customPagination";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import UserFilterSelectInput from "../../components/userFilterSelectInput";
import CourseCard from "../../components/courseCard";
import TextInput from "../../components/textInput";
import AlertPopup from "../../components/alertPopup";
import UserTitleButtons from "../../components/UserTitleButtons";
import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const HomePage = () => {
  const [searchInput, setSearchInput] = useState("");
  const [allData, setAllData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [FilterallData, setFilterAllData] = useState([]);
  // const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [priceRange2, setPriceRange2] = useState([0, 100000]);
  const [level, setLevel] = useState("");
  const [location, setLocation] = useState("");
  const [provider, SetProvider] = useState("");
  const [category, setCategory] = useState("");
  const [sortByName, setSortByName] = useState("title");
  const [sortByPrice, setSortByPrice] = useState("");
  const [selectedTap, setSelectedTab] = useState("all");
  const [openPopup, setOpenPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [state, setstate] = useState("all");
  useMemo(() => {
    setLoading(true);
    const getCoursesData = async () => {
      await axios
        .get(`${axios.defaults.baseURL}/users/courses?status=${state}`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAllData(response.data.data);
            setFilterAllData(response.data.data);
            let prices = response.data.data.map((item) => item.price);
            // Get the minimum and maximum values
            let minValue = Math.min(...prices);
            let maxValue = Math.max(...prices);
            setPriceRange2([minValue, maxValue]);

            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    const getFiltersData = async () => {
      await axios
        .get(`${axios.defaults.baseURL}/courses/getFilterLists`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setFilterData(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getFiltersData();
    getCoursesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state,
    // page,
    // selectedTap,
    // searchInput,
    // priceRange,
    // level,
    // category,
    // provider,
    // location,
    // sortByName,
    // sortByPrice,
    // status,
  ]);
  const sortNameOption = [
    {
      name: "asc",
      value: "title",
    },
    {
      name: "desc",
      value: "-title",
    },
  ];
  const sortPriceOption = [
    {
      name: "price",
      value: "",
    },
    {
      name: "lowest",
      value: "-price",
    },
    {
      name: "highest",
      value: "price",
    },
  ];

  const [sortedData, setSortedData] = useState([]); // Holds sorted data
  // const [pendingCourses, setpendingCourses] = useState([]); // Holds sorted data

  // const pendingCoursesFilter = () => {
  //   axios
  //     .get(`${axios.defaults.baseURL}/users/courses?status=pending`, {
  //       headers: {
  //         authorization: axios.defaults.token,
  //       },
  //     })
  //     .then((res) => res.data)
  //     .then((data) => setpendingCourses(data.data));
  // };
  // const [myCourses, setmyCourses] = useState([]); // Holds sorted data

  // const myCoursesFilter = () => {
  //   axios
  //     .get(`${axios.defaults.baseURL}/users/courses?status=booked`, {
  //       headers: {
  //         authorization: axios.defaults.token,
  //       },
  //     })
  //     .then((res) => res.data)
  //     .then((data) => setmyCourses(data.data));
  // };
  // const [rejectedCourses, setrejectedCourses] = useState([]); // Holds sorted data

  // const rejectedCoursesFilter = () => {
  //   axios
  //     .get(`${axios.defaults.baseURL}/users/courses?status=rejected`, {
  //       headers: {
  //         authorization: axios.defaults.token,
  //       },
  //     })
  //     .then((res) => res.data)
  //     .then((data) => {
  //       setrejectedCourses(data.data);
  //       console.log("testetsefsjefief");
  //     });
  // };

  // useEffect(() => {
  //   pendingCoursesFilter();
  //   myCoursesFilter();
  //   rejectedCoursesFilter();
  // }, []);

  // Function to sort alphabetically by title
  const handleSortName = (e) => {
    setSortByName(e);
    const sorted = FilterallData.sort((a, b) => {
      if (e === "asc") {
        return a.title.localeCompare(b.title); // Sort alphabetically in ascending order
      } else if (e === "desc") {
        return b.title.localeCompare(a.title); // Sort alphabetically in descending order
      }
    });
    setSortedData(sorted);
  };

  // Function to sort by price
  const handleSortPrice = (e) => {
    setSortByPrice(e);
    const sorted = FilterallData.sort((a, b) => {
      console.log(a);
      console.log(b);
      if (e === "lowest") {
        return a.price - b.price; // Sort by price in ascending order
      } else if (e === "highest") {
        return b.price - a.price; // Sort by price in descending order
      }
    });
    setFilterAllData(sorted);
  };

  const handleNav = (page) => {
    setSelectedTab(page);
    // setFilterAllData(allData);
    // setPage(1);
  };

  const providerHandler = (e) => {
    SetProvider(e.target.value);
    if (e.target.value === "") {
      setFilterAllData(allData);
    } else {
      setFilterAllData(allData.filter((l) => l.provider_id == e.target.value));
    }
  };
  const priceHandler = (e, value) => {
    setPriceRange(value);
    // setPage(1);
    console.log(value);
    setFilterAllData(
      allData.filter((item) => item.price >= value[0] && item.price <= value[1])
    );
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  // new filter Code

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);

  const lastPostsIndex = currentPage * postsPerPage; // 1 * 20 = 20
  const firstPostIndex = lastPostsIndex - postsPerPage; // 20 - 20 = 0
  const currentPosts = FilterallData.slice(firstPostIndex, lastPostsIndex);
  const handelPages = (e, p) => {
    setCurrentPage(p);
  };
  // search filter
  const filterDataWithSearch = (e) => {
    if (e === "") {
      setFilterAllData(allData);
    } else {
      setFilterAllData(
        allData.filter((c) => {
          return c.title.toLowerCase().includes(e.toLowerCase());
        })
      );
    }
  };
  // level filter
  const showLevelFunction = (e) => {
    if (e === "") {
      setFilterAllData(allData);
    } else {
      setFilterAllData(allData.filter((l) => l.level_id == e));
    }
  };
  // category filter
  const showCategoryFunction = (e) => {
    // console.log(e + "test");
    if (e === "") {
      setFilterAllData(allData);
    } else {
      setFilterAllData(allData.filter((l) => l.category_id == e));
    }
  };
  // location filter
  const showLocationFunction = (e) => {
    setLocation(e.target.value);
    if (e.target.value === "") {
      setFilterAllData(allData);
    } else {
      setFilterAllData(
        allData.filter((c) => {
          return c.location
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        })
      );
    }
  };

  const [allCourses, setallCourses] = useState([]); // Holds sorted data

  const allCoursesFilter = () => {
    axios
      .get(`${axios.defaults.baseURL}/users/courses?status=all`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data)
      .then((data) => setallCourses(data.data));
  };
  const [pendingCourses, setpendingCourses] = useState([]); // Holds sorted data

  const pendingCoursesFilter = () => {
    axios
      .get(`${axios.defaults.baseURL}/users/courses?status=pending`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data)
      .then((data) => setpendingCourses(data.data));
  };
  const [myCourses, setmyCourses] = useState([]); // Holds sorted data

  const myCoursesFilter = () => {
    axios
      .get(`${axios.defaults.baseURL}/users/courses?status=booked`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data)
      .then((data) => setmyCourses(data.data));
  };
  const [rejectedCourses, setrejectedCourses] = useState([]); // Holds sorted data

  const rejectedCoursesFilter = () => {
    axios
      .get(`${axios.defaults.baseURL}/users/courses?status=rejected`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data)
      .then((data) => setrejectedCourses(data.data));
  };

  useEffect(() => {
    allCoursesFilter();
    pendingCoursesFilter();
    myCoursesFilter();
    rejectedCoursesFilter();
  }, []);

  const { t } = useTranslation("global");

  return (
    <div>
      <Header showLogo={true} />
      <UserTitleButtons />
      <div className="userCoursesPageContainer">
        {/* LEFT SIDE */}
        <div className="filterContainer">
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            {t("courseFilter.title")}
          </p>
          <div className="userFilterInputsWrapper">
            <div className="filterSearchInputContainer">
              <p className="filterInputTitle">{t("courseFilter.search")}</p>
              <div style={{ position: "relative" }}>
                <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                <input
                  type="text"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                    filterDataWithSearch(e.target.value);
                  }}
                  className="userSearchInput"
                  placeholder={t("courseFilter.placeholder")}
                />
              </div>
            </div>
            {filterData?.prices && (
              <div className="userFilterInputsContainer">
                <div className="userFilterGroup">
                  <p className="filterInputTitle">{t("courseFilter.price")}</p>
                  <div className="priceRangeContainer">
                    <p>
                      {priceRange2[0] || "10101"}{" "}
                      {i18next.language === "en" ? "EGP" : "جنية"}
                    </p>
                    <p>
                      {priceRange2[1] || "10101"}{" "}
                      {i18next.language === "en" ? "EGP" : "جنية"}
                    </p>
                  </div>
                  <div style={{ padding: "0px 8px" }}>
                    <Slider
                      value={priceRange}
                      onChangeCommitted={(e, value) => {
                        priceHandler(e, value);
                        // setPage(1);
                      }}
                      valueLabelDisplay="on"
                      min={priceRange2[0] || 0}
                      max={priceRange2[1] || 10000}
                    />
                  </div>
                </div>
                <div className="userFilterGroup">
                  <p className="filterInputTitle">{t("courseFilter.level")}</p>
                  {filterData?.levels && (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={level}
                      onChange={(event) => {
                        setLevel(event.target.value);
                        showLevelFunction(event.target.value);
                      }}
                    >
                      {filterData?.levels.map((level) => {
                        return (
                          <FormControlLabel
                            key={level.id}
                            value={level.id}
                            control={<Radio />}
                            label={level.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  )}
                </div>
                <div className="userFilterGroup">
                  <p className="filterInputTitle">
                    {t("courseFilter.Category")}
                  </p>
                  {filterData?.categories && (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={category}
                      onChange={(event) => {
                        setCategory(event.target.value);
                        showCategoryFunction(event.target.value);

                        // setPage(1);
                      }}
                    >
                      {filterData.categories.map((category) => {
                        return (
                          <FormControlLabel
                            key={category.id}
                            value={category.id}
                            control={<Radio />}
                            label={category.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  )}
                </div>
                <div className="userFilterGroup">
                  <p className="filterInputTitle">Provider</p>

                  {filterData?.providers && (
                    <UserFilterSelectInput
                      value={provider}
                      options={filterData?.providers}
                      onChange={(e) => providerHandler(e)}
                    />
                  )}
                </div>
                <div className="userFilterGroup">
                  <p className="filterInputTitle">
                    {t("courseFilter.Location")}
                  </p>
                  <TextInput
                    style={{ marginTop: "5px" }}
                    value={location}
                    onChange={(e) => {
                      showLocationFunction(e);
                      // setPage(1);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* RIGHT SIDE */}
        <>
          <div className="contentContainer">
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="contentContainerTapSwitch"
            >
              <div
                className="contentContainerTapSwitchxxx"
                style={{
                  display: "flex",
                  border: "1px solid var(--c300)",
                  borderRadius: "50px",
                  width: "fit-content",
                  backgroundColor: "#F4F4F6",
                }}
              >
                <button
                  value="1"
                  className={
                    selectedTap === "all" ? "selectedTap" : "notSelectedTap"
                  }
                  onClick={(e) => {
                    handleNav("all");
                    setstate("all");
                  }}
                >
                  {t("courseFilter.all")} ({allCourses.length})
                </button>
                <button
                  value="2"
                  className={
                    selectedTap === "approved"
                      ? "selectedTap"
                      : "notSelectedTap"
                  }
                  onClick={(e) => {
                    handleNav("approved");
                    setstate("booked");
                  }}
                >
                  {t("courseFilter.mycourses")} ({myCourses.length})
                </button>
                <button
                  value="3"
                  className={
                    selectedTap === "pending" ? "selectedTap" : "notSelectedTap"
                  }
                  onClick={(e) => {
                    handleNav("pending");
                    setstate("pending");
                  }}
                >
                  {t("courseFilter.pending")} ({pendingCourses.length})
                </button>
                <button
                  value="4"
                  className={
                    selectedTap === "rejected"
                      ? "selectedTap"
                      : "notSelectedTap"
                  }
                  onClick={(e) => {
                    handleNav("rejected");
                    setstate("rejected");
                  }}
                >
                  {t("courseFilter.rejected")} ({rejectedCourses.length})
                </button>
              </div>
              <div>
                <select
                  className="filterSelectInput"
                  style={{
                    width: "60px",
                    border: "none",
                    backgroundColor: "#e9e9e9",
                  }}
                  onChange={(e) => handleSortName(e.target.value, "name")}
                >
                  {sortNameOption.map((option) => {
                    return (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
                <select
                  className="filterSelectInput"
                  style={{
                    width: "70px",
                    border: "none",
                    backgroundColor: "#e9e9e9",
                  }}
                  onChange={(e) => handleSortPrice(e.target.value)}
                >
                  {sortPriceOption.map((option) => {
                    return (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {!loading ? (
              <>
                <div className="userPageContent">
                  {currentPosts?.map((course) => (
                    <CourseCard
                      key={course.id}
                      course={course}
                      showAlertPopup={showAlertPopup}
                    />
                  ))}
                </div>
                <Pagination
                  count={Math.ceil(FilterallData.length / 8)}
                  color="primary"
                  size={"small"}
                  onChange={handelPages}
                />
              </>
            ) : (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  height: "500px",
                }}
              >
                loading...
              </p>
            )}
            {openPopup ? (
              <AlertPopup
                title={status}
                subTitle={message}
                openPopup={handlePopup}
              />
            ) : null}
          </div>
        </>
      </div>
    </div>
  );
};

export default HomePage;
