import React, { useCallback } from "react";
import style from "./popupstyle.module.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
function EditTable({ tableData, onCancel }) {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState(tableData);

  const typeRenderer = (e) => {
    return (
      <span
        style={{
          background:
            e.value == "Extroversion"
              ? "#7A67C530"
              : e.value == "Agreeableness"
              ? "#0071C130"
              : e.value == "Conscientiousness"
              ? "#FBD34D30"
              : e.value == "Neuroticism"
              ? "#15803D30"
              : "#B91C1C30",
          padding: "5px 10px",
          borderRadius: "20px",
        }}
      >
        {e.value}
      </span>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    { field: "order", rowDrag: true, headerName: "index", flex: 1 },
    { field: "ques_en", headerName: "English Question", flex: 2 },
    { field: "ques_ar", headerName: "Arabic Question", flex: 2 },
    {
      field: "factor",
      headerName: "Type",
      flex: 1,
      cellRenderer: typeRenderer,
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
    };
  }, []);
  const rowSelection = useMemo(() => {
    return { mode: "multiRow", checkboxes: false, headerCheckbox: false };
  }, []);

  const onGridReady = useCallback((params) => {
    setRowData(tableData);
  }, []);

  // const handleRowDragEnd = (event) => {
  //   const allRowData = [];
  //   event.api.forEachNode((node) => allRowData.push(node.data));

  //   // Log the new order of rows
  //   console.log("New Order of Rows:", allRowData);
  // };

  useEffect(() => {}, [tableData]);
  const [reorderedQuestionsstate, setreorderedQuestionsstate] = useState();
  const handleRowDragEnd = (event) => {
    const reorderedQuestions = [];
    // Loop through the reordered data
    event.api.forEachNode((node, index) => {
      reorderedQuestions.push({ id: node.data.id, order: index + 1 });
    });

    const resultObject = { questions: reorderedQuestions };
    setreorderedQuestionsstate({ questions: reorderedQuestions });
    // Log the result object in the desired format
    console.log(resultObject);

    // If needed, you can send this resultObject to an API
    // postReorderedDataToAPI(resultObject);
  };

  const handleReset = () => {
    setRowData(tableData);
    console.log("test");
  };

  const updatereorderedQuestionsstate = () => {
    console.log(reorderedQuestionsstate);
    axios
      .post(
        `https://ed.edmart.co.uk/api/administration/questions/reorder`,
        reorderedQuestionsstate,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 || 201) {
          Swal.fire({
            title: "Good job!",
            text: "Reorder the Questions is Done 🎉",
            icon: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! or you don't have a permission",
        });
      });
  };

  return (
    <div className={style.parentpopup}>
      <div
        className={style.popupdiv}
        style={{ width: "80%", height: "85vh", padding: "20px" }}
      >
        <h2 className={style.title}>Edit Question</h2>
        <p className="mb-1" style={{ marginBottom: "10px" }}>
          Drag and drop questions to arrange them
        </p>
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "80%" }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowDragManaged={true}
            rowDragMultiRow={true}
            rowSelection={rowSelection}
            onGridReady={onGridReady}
            onRowDragEnd={handleRowDragEnd}
          />
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "10px",
          }}
        >
          <div className="parbutns">
            <Button
              onClick={onCancel}
              className={style.buttonsCancel}
              style={{ width: "49%", border: "1px solid #1976d2" }}
            >
              Cancel
            </Button>
            <Button
              onClick={updatereorderedQuestionsstate}
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: "49%" }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTable;
