import { useEffect, useState } from "react";
import axios from "../../axios";
import TextInput from "../textInput";
import SelectInput from "../selectInput";
const AdminFormModal = ({
  title,
  handleCloseButton,
  showAlertPopup,
  id,
  allData,
  roles,
  adminData,
}) => {
  console.log(adminData);
  const [adminName, setAdminName] = useState(adminData?.name);
  const [adminEmail, setAdminEmail] = useState(adminData?.email);
  const [adminPassword, setAdminPassword] = useState("");
  const [roleId, setRoleId] = useState(adminData?.role_id);
  const [isActive, setIsActive] = useState(adminData?.status);
  const [loading, setLoading] = useState(true);
  const [nameValidation, setNameValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [roleValidation, setRoleValidation] = useState(false);
  const [adminID, setAdminID] = useState("");
  const [adminIDcheck, setAdminIDcheck] = useState(false);

  useEffect(() => {
    if (id) {
      setAdminID(id);
      handleDefaultValue();
      setAdminIDcheck(true);
    } else {
      setIsActive(options[0].id);
      setRoleId(roles[0]?.id);
      console.log(roles);
      setAdminIDcheck(false);
    }
    setLoading(false);
  }, []);
  const handleDefaultValue = () => {
    console.log(allData, "Check allData content");
    let itemData = allData?.find((itemID) => itemID.id === id);
    if (itemData) {
      setAdminName(itemData.name || "");
      setAdminEmail(itemData.email || "");
      setAdminPassword(itemData.password || "");
      setIsActive(itemData.status || options[0].id);
      let adminRole = roles?.find(
        (roleName) => roleName.name === itemData.role
      );
      setRoleId(adminRole?.id || "");
      console.log("test" + adminRole?.id);
    } else {
      console.log("Item data not found for the given id");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    let axiosBody = {};
    adminID
      ? (url = `${axios.defaults.baseURL}/administration/${adminID}`)
      : (url = `${axios.defaults.baseURL}/administration`);

    adminID
      ? (axiosBody = {
          name: adminName,
          email: adminEmail,
          roleId: roleId,
          isActive: isActive,
          _method: "put",
        })
      : (axiosBody = {
          name: adminName,
          email: adminEmail,
          roleId: roleId,
          isActive: isActive,
          password: adminPassword,
        });
    await axios
      .post(url, axiosBody, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 202) {
          handleCloseButton();
          setAdminName("");
          setAdminEmail("");
          setAdminPassword("");
          setRoleId("");
          setIsActive("");
          showAlertPopup(response.data.status, response.data.message);
        } else if (response.status === 200) {
          handleCloseButton();
          setAdminName("");
          setAdminEmail("");
          setAdminPassword("");
          setRoleId("");
          setIsActive("");
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
          console.log(error.response);
          setNameValidation(error.response.data.data.name);
          setEmailValidation(error.response.data.data.email);
          setPasswordValidation(error.response.data.data.password);
          setRoleValidation(error.response.data.data.roleId);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  const options = [
    { name: "Blocked", id: 0 },
    { name: "Active", id: 1 },
  ];
  return (
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="formContainer">
          <p className="welcome-text">{title}</p>
          <form className="form" method="post">
            {!loading ? (
              <>
                <TextInput
                  label="Admin Name"
                  value={adminName || adminData?.name}
                  placeholder="Admin Name"
                  required={true}
                  validation={nameValidation}
                  onChange={(e) => {
                    setAdminName(e.target.value);
                  }}
                />
                <TextInput
                  label="Email"
                  value={adminEmail || adminData?.email}
                  placeholder="Email"
                  required={true}
                  validation={emailValidation}
                  onChange={(e) => {
                    setAdminEmail(e.target.value);
                  }}
                />
                {!adminIDcheck ? (
                  <TextInput
                    label="Password"
                    value={adminPassword}
                    placeholder="Password"
                    required={true}
                    validation={passwordValidation}
                    onChange={(e) => {
                      setAdminPassword(e.target.value);
                    }}
                  />
                ) : null}
                <SelectInput
                  label="Role"
                  value={roleId}
                  validation={roleValidation}
                  options={roles}
                  onChange={(e) => setRoleId(e.target.value)}
                />
                <SelectInput
                  label="Active Status"
                  value={isActive}
                  options={options}
                  onChange={(e) => setIsActive(e.target.value)}
                />
              </>
            ) : null}
            <div
              className="modalFormButtons"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <button className="secondaryButton" onClick={handleCloseButton}>
                Cancel
              </button>
              <button
                className="primaryButton"
                type="submit"
                onClick={handleSubmit}
              >
                {adminID ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminFormModal;
